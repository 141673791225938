import React from "react";
import '../styles/components/projectfull.scss';
import { Link } from "gatsby";
import arrow from "../images/arrow.svg";
import Pagetitle from "./Pagetitle";
import Bodytext from "./Bodytext";

export default props =>
  <div className="project--full--wrapper">
    <section className="project--wrapper container">
      <div className="project--full--description--wrapper">
        <Pagetitle pageTitle={props.projectfullname} />
        <Bodytext bodyText={props.projectfulldescription} />
      </div>
      <div className="project--full--info">
        <div className="project--client">
            <h3>Client</h3>
            <ul>
              <li>{props.projectclient}</li>
            </ul>
        </div>
        <div className="project--role">
            <h3>My role</h3>
            <ul>
              <li>{props.projectrole}</li>
            </ul>
        </div>
        <div className="project--date">
            <h3>Date</h3>
            <ul>
                <li>{props.projectdate}</li>
            </ul>
        </div>
      </div>
    </section>
    <section className="project--images--full container">
      {props.projectfullimg}
    </section>
    <section className="link--section container">
      <Link className="back btn" to="/projects"><img src={arrow} alt="See all projects"/><h2>See all projects</h2></Link>
      <Link className="next btn" to={props.projectnextlink}><h2>Next project</h2><img src={arrow} alt="Next project"/></Link>
    </section>
</div>

