import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../lotties/logo-animationv2.json';
import {Link} from "gatsby";

class UncontrolledLottie extends Component {
  state = {isStopped: true}

  render(){
    const defaultOptions = {
      renderer: "canvas",
      loop: false,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <Link to="/" className="lotties"
            onMouseEnter={() => this.setState({isStopped: false})}
            onMouseLeave={() => this.setState({isStopped: true})}>

        <Lottie options={defaultOptions}
            height={115}
            width={115}
            isStopped={this.state.isStopped}
        />
      </Link>
    )
  }
}

export default UncontrolledLottie