import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import shoptityoneullImg from "../../images/projectsfull/shoptityonefull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ShoptityOne</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="ShoptityOne"
              projectfulldescription="EntityOne interns were assigned to develop an internal reward store for the company. I designed this website keeping in mind that this shouldn’t be corporate but had to look fun and playful."
              projectclient="UGent"
              projectrole="Web Design"
              projectdate="2019"
              projectfullimg={<div className="project--img--inner"><img data-src={shoptityoneullImg} className="project--img lazyload" alt="ShoptityOne" /></div>}
              projectnextlink="/projects/shanazrazik"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;